import React from 'react';
import { Col, Divider, Row } from 'antd';

interface Stat {
  value: string | number;
  desc: string;
}

interface StatsProps {
  stats: Stat[];
}

const StatItem: React.FC<Stat> = ({ value, desc }) => (
  <Col span={3} className='stat'>
    <Row justify='center' className='count'>
      <Col span={24}>{value}</Col>
    </Row>
    <Row justify='center' className='desc'>
      <Col span={24}>{desc}</Col>
    </Row>
  </Col>
);

export const Stats: React.FC<StatsProps> = ({ stats }) => (
  <Row justify='center'>
    {stats?.map((stat, index) => (
      <React.Fragment key={index}>
        <StatItem {...stat} />
        {index + 1 !== stats.length && (
          <Divider type='vertical' className='divider' />
        )}
      </React.Fragment>
    ))}
  </Row>
);
