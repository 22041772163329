import { Col, Row } from "antd";
import React from "react";
import MainHeader from "./MainHeader";

export default class NotFoundPage extends React.Component<any, any> {
    constructor(props: any) { 
        super(props)

        this.state = {
            os: this.get_platform()
        }
    }

    get_platform() {
        // 2022 way of detecting. Note : this userAgentData feature is available only in secure contexts (HTTPS)
        if (typeof (navigator as any).userAgentData !== 'undefined' && (navigator as any).userAgentData != null) {
            return (navigator as any).userAgentData.platform;
        }
        // Deprecated but still works for most of the browser
        if (typeof navigator.platform !== 'undefined') {
            if (typeof navigator.userAgent !== 'undefined' && /android/.test(navigator.userAgent.toLowerCase())) {
                // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
                return 'android';
            }
            return navigator.platform;
        }
        return 'unknown';
    };


    render() {
        return <>
            <Row className="container">
                <Col xl={24} xs={24} className="main-section">
                    <Row className="flex-container-404">
                        <Col>
                            <Row className="main-header" justify="space-between">
                                <Col span={24}>
                                    <MainHeader os={this.state.os}></MainHeader>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="" justify="center" style={{height: "150px", fontSize: "24px", color: "white", textAlign: "center", marginTop:"140px"}}>
                                <Col span={24}>
                                    Ooops... Seems you missed a turn somewhere...
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    }
}
