import React from 'react';
import { Col, Row } from 'antd';
import { logoAlphaSrc, logoSrc } from 'src/constants/constants';
import { getAppDownloadUrl } from 'src/services/helpers';

interface MainHeaderProps {
  os: string;
}

const MainHeader: React.FC<MainHeaderProps> = ({ os }) => {
  return (
    <Row className='main-header-sub' justify='space-between'>
      <Col className='logo-holder' sm={8} xs={{ span: 12 }}>
        <img width={30} height={30} src={logoSrc} alt='Skratch Logo' />
        <img
          className='logo-alpha'
          width={85}
          height={20}
          src={logoAlphaSrc}
          alt='Skratch'
        />
      </Col>
      <Col
        className='app-download fixed'
        sm={12}
        xs={24}
        lg={10}
        xl={9}
        xxl={7}
      >
        <Row justify='space-between' align='middle'>
          <Col
            xs={15}
            sm={10}
            md={11}
            lg={13}
            xl={12}
            xxl={11}
            className='download-text'
          >
            <Row align='middle'>
              <Col className='hidden-sub-logo'>
                <img width={30} height={30} src={logoSrc} alt='Skratch Logo' />
              </Col>
              <Col flex='auto'>Want to build your own Skratch map?</Col>
            </Row>
          </Col>
          <Col className='download-link-btn'>
            <a
              href={getAppDownloadUrl(os)}
              className='button download-link-text'
              target='_blank'
              rel='noreferrer'
            >
              Get Skratch
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MainHeader;
