import { Country } from 'src/types/Country';

type StatItem = { name: string; value: string | number; desc: string };
type CountryData = { countries: Country[]; totalRegionCount: number };

export default class DataService {
  async getMapCountries(): Promise<any> {
    return fetch(`${process.env.REACT_APP__API_URL}/share-api/map`).then(
      (res) => res.json()
    );
  }

  processMarkedCountries(
    list: {
      territory: string[];
    },
    mapData: any[]
  ): Map<string, CountryData | Country[]> {
    const markedCountries =
      mapData.filter((it) => list.territory?.includes(it.alpha3Code)) || [];
    const markedWorldRegions = Array.from(
      new Set(markedCountries.map((it) => it.territoryWRegion))
    ).sort();
    const result = new Map<string, CountryData | Country[]>();

    for (const region of markedWorldRegions) {
      result.set(region, {
        countries: markedCountries.filter(
          (it) => it.territoryWRegion === region
        ),
        totalRegionCount: mapData.filter((it) => it.territoryWRegion === region)
          .length,
      });
    }
    result.set('flattened', markedCountries);

    return result;
  }

  prepareStats(
    countryList: Map<string, CountryData | Country[]> | undefined,
    userData: {
      visited: { city: string[]; region: string[] };
      wantTo: { city: string[]; region: string[] };
      wil: { territory: string[] };
    },
    viewType: 'visited' | 'wantTo',
    mapData: any[]
  ): StatItem[] | undefined {
    if (!countryList) return undefined;

    const flattened = (countryList.get('flattened') as Country[]) || [];
    const flattenedLength = flattened.length;

    const commonStats: StatItem[] = [
      this.createStat(
        'countriesCount',
        `${flattenedLength} / ${mapData.length}`,
        'country',
        'countries'
      ),
      this.createStat(
        'percentOfTheWorld',
        `${Math.round((flattenedLength / mapData.length) * 100)}%`,
        'of the world'
      ),
    ];

    const specificStats: StatItem[] =
      viewType === 'visited'
        ? [
            this.createStat(
              'worldRegionsCount',
              `${countryList.size - 1} / 10`,
              'world regions'
            ),
            this.createStat(
              'citiesCount',
              userData.visited.city?.length || 0,
              'city',
              'cities'
            ),
            this.createStat(
              'regionsCount',
              userData.visited.region?.length || 0,
              'region',
              'regions'
            ),
            this.createStat(
              'livedCount',
              userData.wil.territory?.length || 0,
              'country lived',
              'countries lived'
            ),
          ]
        : [
            this.createStat(
              'cities',
              userData.wantTo.city?.length || 0,
              'city',
              'cities'
            ),
            this.createStat(
              'regions',
              userData.wantTo.region?.length || 0,
              'region',
              'regions'
            ),
          ];

    return [...commonStats, ...specificStats];
  }

  private createStat(
    name: string,
    value: string | number,
    singularDesc: string,
    pluralDesc: string = singularDesc
  ): StatItem {
    const desc =
      typeof value === 'number' && value === 1 ? singularDesc : pluralDesc;
    return { name, value, desc };
  }

  prepareCityRegionData(
    data: { city: string[]; region: string[] },
    countryList: Country[]
  ): Record<string, { cities: string[]; regions: string[] }> {
    return countryList.reduce((acc, { alpha3Code, alpha2Code }) => {
      acc[alpha3Code] = {
        cities:
          data.city?.filter((city) =>
            city.toLowerCase().startsWith(alpha3Code.toLowerCase())
          ) || [],
        regions:
          data.region?.filter((region) =>
            region.toLowerCase().startsWith(alpha2Code.toLowerCase())
          ) || [],
      };
      return acc;
    }, {} as Record<string, { cities: string[]; regions: string[] }>);
  }
}
