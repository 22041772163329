import React from 'react';
import './App.css';
import 'antd/dist/antd.min.css';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Share from './components/Share.component';
import NotFoundPage from './components/NotFoundPage';
class App extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
      userSecret: null,
      viewType: null
    }
    this.handleUpdateViewType = this.handleUpdateViewType.bind(this)
  }

  handleUpdateViewType(userSecret: string, viewType: string) {
    this.setState({ viewType: viewType, userSecret: userSecret })
  }

  render() {
    const Wrapper = (props: any) => {
      let params: any = useParams();

      if (!params.viewType) {
        params.viewType = 'visited';
      }
      return <Share updateViewType={this.handleUpdateViewType} {...{ ...props, match: { params }, navigate: useNavigate() }} />
    }

    return (
      <Routes>
        <Route path='/:userSecret/:viewType' element={<Wrapper />} />
        <Route path="/:userSecret" element={<Wrapper />} />
        <Route path='/*' element={<NotFoundPage />} />
      </Routes>
    );
  }
}

export default App;
