export const getAppDownloadUrl = (os: string) => {
  const lowerOs = os.toLowerCase();
  if (['ios', 'iphone'].includes(lowerOs)) {
    return 'https://apps.apple.com/us/app/skratch-where-ive-been/id1457438876';
  } else if (lowerOs === 'android') {
    return 'https://play.google.com/store/apps/details?id=world.skratch.app';
  }
  return 'https://www.skratch.world';
};

export const getPlatform = (navigator: any): string => {
  if ('userAgentData' in navigator && navigator.userAgentData) {
    return navigator.userAgentData.platform;
  }

  if (navigator.platform) {
    if (navigator.userAgent && /android/i.test(navigator.userAgent)) {
      return 'Android';
    }
    return navigator.platform;
  }

  return 'Unknown';
};

export const getTooltipFlag = (code: any) => {
  return process.env.REACT_APP__APP_PATH_PREFIX + '/flags/' + code + '.png';
};
