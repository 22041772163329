import React from 'react';
import { Col, Row, Image } from 'antd';

interface ProfileProps {
  userAvatar: string;
  userName: string;
}

const ProfileComponent: React.FC<ProfileProps> = ({ userAvatar, userName }) => (
  <>
    <Row justify='center' className='profile-photo'>
      <Col span={6} className='profile-photo'>
        <Image width={48} height={48} src={userAvatar} preview={false} />
      </Col>
    </Row>
    <Row justify='center'>
      <Col className='user-name'>{userName}</Col>
    </Row>
  </>
);

export default ProfileComponent;
