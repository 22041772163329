import { Row, Col, Image, Divider } from 'antd';
import React from 'react';
import ReactTooltip from 'react-tooltip';

export default class ListingComponent extends React.Component<any, any> {
  windowWidth: number;
  constructor(props: any) {
    super(props);
    this.windowWidth = window.innerWidth;
  }
  getFlag(code: string) {
    return `${process.env.REACT_APP__APP_PATH_PREFIX}/flags/${code}.png`;
  }
  handleMouseOver(country: any) {
    if (this.windowWidth < 800) return;
    this.props.onListingHover(country.alpha3Code);
  }
  handleMouseLeave() {
    if (this.windowWidth < 800) return;
    this.props.onHover(undefined, null);
  }

  getAdditionalCountryInfo(code: string) {
    if (
      this.props.citiesAndRegionsByCountry &&
      this.props.citiesAndRegionsByCountry[code]
    ) {
      let info = this.props.citiesAndRegionsByCountry[code];
      if (!info || info === undefined) {
        return '';
      }
      let html = '';
      if (info.cities.length) {
        html +=
          info.cities.length.toString() +
          (info.cities.length > 1 ? ' cities' : ' city');
      }
      if (info.regions.length) {
        if (info.cities.length) html += ' ∙ ';
        html +=
          info.regions.length.toString() +
          (info.regions.length > 1 ? ' regions' : ' region');
      }
      return html;
    } else {
      return 'TEST';
    }
  }

  render() {
    let wRegions = Array.from(this.props.countriesByWRegion.keys()).filter(
      (it) => it !== 'flattened'
    );
    return (
      <>
        <Row justify='center' className='country-listing'>
          <Col span={24} className='country-listing-items-container'>
            {this.props.countriesByWRegion &&
              wRegions.map((wRegion: any, index: number) => (
                <React.Fragment key={index}>
                  <Row justify='center' className='listing-head'>
                    <Col span={20} className='country-name'>
                      {wRegion}
                    </Col>
                    <Col span={4} className='country-count'>
                      {
                        this.props.countriesByWRegion.get(wRegion).countries
                          .length
                      }
                      {this.props.viewSelected === 'visited'
                        ? '/' +
                          this.props.countriesByWRegion.get(wRegion)
                            .totalRegionCount
                        : ''}
                    </Col>
                  </Row>
                  {this.props.countriesByWRegion
                    .get(wRegion)
                    .countries.map((country: any, i: number) => (
                      <Row
                        justify='center'
                        align='middle'
                        style={{ cursor: 'pointer' }}
                        className={
                          this.props.hoveredCountry === country.alpha3Code
                            ? 'listing-sub hovered'
                            : 'listing-sub'
                        }
                        key={index + '' + i}
                        onMouseOver={(e: any) => this.handleMouseOver(country)}
                        onMouseLeave={(e: any) => this.handleMouseLeave()}
                      >
                        <Col span={20}>
                          <Image
                            alt={country.territoryName}
                            preview={false}
                            src={this.getFlag(country.alpha3Code)}
                            height={32}
                            width={32}
                          ></Image>
                          <div
                            style={{
                              paddingLeft: '16px',
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              maxWidth: '85%',
                            }}
                          >
                            <div>{country.territoryName}</div>
                            <div className='country-info-additional-listing'>
                              {this.getAdditionalCountryInfo(
                                country.alpha3Code
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                          {this.props.livedTerritoryCodes.includes(
                            country.alpha3Code
                          ) && (
                            <>
                              <div
                                data-tip='Has lived here'
                                data-for='tooltip'
                                data-place='top'
                              >
                                <Image
                                  className='listing-flag'
                                  preview={false}
                                  src={
                                    process.env.REACT_APP__APP_PATH_PREFIX +
                                    '/images/house.png'
                                  }
                                  height={16}
                                  width={16}
                                />
                              </div>
                              {this.windowWidth > 800 && (
                                <ReactTooltip
                                  id='tooltip'
                                  backgroundColor={'#140231'}
                                  textColor={'#FFF'}
                                  class='small-tooltip'
                                />
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    ))}
                  <Divider
                    style={{
                      marginTop: '16px',
                      marginBottom: '8px',
                      background: '#E9E9F1',
                    }}
                  />
                </React.Fragment>
              ))}
          </Col>
        </Row>
      </>
    );
  }
}
