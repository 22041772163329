import React from "react";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps"
import { geoTimes } from "d3-geo-projection";
import markers from './../constants/markers'
export class World extends React.Component<any, any> {
    windowWidth = window.innerWidth

    constructor(props: any) {
        super(props)
        this.state = {
            tooltipContent: props.tooltipContent,
            forVisitedMark: props.countryList.get('flattened').map((it: any) => it.alpha3Code)
        }
    }

    handleMouseOver(geo: any, ev: any): void {
        if (this.windowWidth <= 800) return;
        this.props.onHover(geo.properties.ISO_A3, { x: ev.x, y: ev.y })
    }

    handleMouseLeave(): void {
        if (this.windowWidth <= 800) return;
        this.props.onHover(null, null)
    }

    handleMouseMove(geo: any, ev: any): void {
        if (!geo || this.windowWidth <= 800) {
            return;
        }

        this.props.adjustTooltipPosition(ev.pageX, ev.pageY)
    }

    getFillColor(geography: any): string {
        return this.state.forVisitedMark.includes(geography.properties.ISO_A3) ? "#FFF" : "rgba(255, 255, 255, 0.25)"
    }

    getHoverFillColor(): string | undefined {
        if (this.windowWidth <= 800) return;
        return this.props.viewSelected === 'want-to-visit' ? "#3ACCE1" : "#ED008E"
    }

    projection = geoTimes()
        .translate([980 / 2, 50 + 551 / 2])
        .scale(205)
        .rotate([-11, 0, 0]);

    render(): JSX.Element {
        return <ComposableMap
            width={1000}
            height={540}
            style={{
                width: this.windowWidth > 576 ? "90%" : "100%",
                height: "auto"
            }}
            projectionConfig={{
                scale: 205,
                rotate: [-11, 0, 0]
            }}
            projection={this.projection}>
            <Geographies geography={process.env.REACT_APP__APP_PATH_PREFIX + '/geo/world-110m.json'}>
                {({ geographies }) =>
                    geographies.map((geo) => (
                        <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            style={{
                                default: {
                                    fill: this.getFillColor(geo),
                                    outline: "none",
                                    pointerEvents: this.windowWidth <= 800 ? "none" : "auto"
                                },
                                hover: {
                                    fill: this.getHoverFillColor(),
                                    strokeWidth: 0,
                                    outline: "none",
                                    cursor: "pointer",
                                }
                            }}
                            onMouseOver={(ev) => this.handleMouseOver(geo, ev)}
                            onMouseMove={(ev) => this.handleMouseMove(geo, ev)}
                            onMouseLeave={() => this.handleMouseLeave()}
                        />
                    ))
                }
            </Geographies>
            {markers.map((marker: any) => (
                <Marker key={marker.alpha3Code} coordinates={marker.coords}>
                    <circle r={1} fill="none" id={marker.alpha3Code} />
                </Marker>
            ))}
        </ComposableMap>
    }
}
