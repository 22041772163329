const markers: any[] = [
    { alpha3Code: "AFG", coords: [66.08892294012779, 33.885820814815865] },
    { alpha3Code: "AGO", coords: [17.469204403054352, -12.267408202394886] },
    { alpha3Code: "ALB", coords: [20.031971797499992, 41.14421884868903] },
    { alpha3Code: "ARE", coords: [54.2071519072786, 23.871122173753882] },
    { alpha3Code: "ARG", coords: [-65.1002827475065, -35.86291295381194] },
    { alpha3Code: "ARM", coords: [44.99841799606245, 40.221112224559306] },
    { alpha3Code: "ATA", coords: [24.12894824484343, -81.07319017338139] },
    { alpha3Code: "ATF", coords: [69.53297145863388, -49.3046079398012] },
    { alpha3Code: "AUS", coords: [134.41252177288362, -25.9160400991751] },
    { alpha3Code: "AUT", coords: [14.077754059838583, 47.617484688073255] },
    { alpha3Code: "AZE", coords: [47.55167528250031, 40.223758206867345] },
    { alpha3Code: "BDI", coords: [29.91364723824763, -3.3774521671090536] },
    { alpha3Code: "BEL", coords: [4.583358725034393, 50.65406642957671] },
    { alpha3Code: "BEN", coords: [2.337245119578108, 9.653691400523725] },
    { alpha3Code: "BFA", coords: [-1.7758984119326857, 12.315077753431657] },
    { alpha3Code: "BGD", coords: [90.26813944609398, 23.84387614488262] },
    { alpha3Code: "BGR", coords: [25.1958399603044, 42.75682350466884] },
    { alpha3Code: "BHS", coords: [-77.92561800091377, 25.52914126897591] },
    { alpha3Code: "BIH", coords: [17.81681309820294, 44.1853173633342] },
    { alpha3Code: "BLR", coords: [27.983430738939262, 53.520337765249074] },
    { alpha3Code: "BLZ", coords: [-88.70451148586082, 17.200051233662204] },
    { alpha3Code: "BOL", coords: [-64.62584688182706, -16.754845183421104] },
    { alpha3Code: "BRA", coords: [-52.94777324931338, -10.960327303619868] },
    { alpha3Code: "BRN", coords: [114.91077236657692, 4.689688585030066] },
    { alpha3Code: "BTN", coords: [90.47584862665718, 27.427345861669036] },
    { alpha3Code: "BWA", coords: [23.76959928121347, -22.1195022857451] },
    { alpha3Code: "CAF", coords: [20.37457956513717, 6.54535272301747] },
    { alpha3Code: "CAN", coords: [-97.93573052953211, 62.561658560264114] },
    { alpha3Code: "CHE", coords: [8.115846822141707, 46.79379827190459] },
    { alpha3Code: "CHL", coords: [-71.29250288784516, -39.947575068093904] },
    { alpha3Code: "CHN", coords: [103.80976920523186, 36.90759741061942] },
    { alpha3Code: "CIV", coords: [-5.611442230250897, 7.5572945048506615] },
    { alpha3Code: "CMR", coords: [12.612237853442894, 5.670208230789572] },
    { alpha3Code: "COD", coords: [23.57877213543378, -2.860185428390875] },
    { alpha3Code: "COG", coords: [15.135234214719807, -0.8385014834022403] },
    { alpha3Code: "COL", coords: [-73.05783300990136, 3.935088966849111] },
    { alpha3Code: "CRI", coords: [-84.17295370185893, 9.966672076868829] },
    { alpha3Code: "CUB", coords: [-78.95203293619169, 21.633076509478407] },
    { alpha3Code: "CYP", coords: [33.038779702666844, 34.90773782998135] },
    { alpha3Code: "CZE", coords: [15.33290094051308, 49.77827425217921] },
    { alpha3Code: "DEU", coords: [10.291192709870304, 51.16716426432824] },
    { alpha3Code: "DJI", coords: [42.496925775968926, 11.773294711150402] },
    { alpha3Code: "DNK", coords: [9.876810345674352, 56.069678636632375] },
    { alpha3Code: "DOM", coords: [-70.46153222333153, 18.88422792001737] },
    { alpha3Code: "DZA", coords: [2.600321619958169, 28.282986290774716] },
    { alpha3Code: "ECU", coords: [-78.38073255096764, -1.4565218561795497] },
    { alpha3Code: "EGY", coords: [29.837001534204806, 26.546270208890967] },
    { alpha3Code: "ERI", coords: [38.68069845677972, 15.428018256050482] },
    { alpha3Code: "ESP", coords: [-3.6153747706532218, 40.378490380632094] },
    { alpha3Code: "EST", coords: [25.826861365677207, 58.646774750513146] },
    { alpha3Code: "ETH", coords: [39.54743814371428, 8.665817344834101] },
    { alpha3Code: "FIN", coords: [26.211007299873124, 64.59530349310047] },
    { alpha3Code: "FJI", coords: [178.56171738929604, -17.317290978113967] },
    { alpha3Code: "FLK", coords: [-59.421557087482284, -51.712257862357106] },
    { alpha3Code: "FRA", coords: [-2.539317943661766, 43.23528686823288] },
    { alpha3Code: "GAB", coords: [11.686666433650624, -0.6484788533311749] },
    { alpha3Code: "GBR", coords: [-2.8564979434580753, 53.96733530373366] },
    { alpha3Code: "GEO", coords: [43.47703281939017, 42.16553174495085] },
    { alpha3Code: "GHA", coords: [-1.2355732941253013, 7.931856732160721] },
    { alpha3Code: "GIN", coords: [-11.061378719313149, 10.45179263638231] },
    { alpha3Code: "GMB", coords: [-15.434624714831124, 13.475170472414487] },
    { alpha3Code: "GNB", coords: [-15.112297432506654, 12.023262643946346] },
    { alpha3Code: "GNQ", coords: [10.365253313084237, 1.6440673789731568] },
    { alpha3Code: "GRC", coords: [22.717764850694213, 39.08765333951071] },
    { alpha3Code: "GRL", coords: [-41.45925956840073, 75.18149921270772] },
    { alpha3Code: "GTM", coords: [-90.36918649854869, 15.70263527232981] },
    { alpha3Code: "GUY", coords: [-58.96551880042006, 4.7955808209914395] },
    { alpha3Code: "HND", coords: [-86.58801033930352, 14.824041069291633] },
    { alpha3Code: "HRV", coords: [16.565280146375713, 45.02162877496011] },
    { alpha3Code: "HTI", coords: [-72.65670431109979, 18.900080625244613] },
    { alpha3Code: "HUN", coords: [19.358484459325982, 47.203820330215464] },
    { alpha3Code: "IDN", coords: [117.39290439935046, -2.2267384141626017] },
    { alpha3Code: "IND", coords: [79.55025344169994, 23.050738081069774] },
    { alpha3Code: "IRL", coords: [-8.00963142663769, 53.18730103697271] },
    { alpha3Code: "IRN", coords: [54.25795881877849, 32.589031100012754] },
    { alpha3Code: "IRQ", coords: [43.75348267468925, 33.062761062683705] },
    { alpha3Code: "ISL", coords: [-18.762649691518533, 65.08011493373252] },
    { alpha3Code: "ISR", coords: [35.0055104713093, 31.491832614629946] },
    { alpha3Code: "ITA", coords: [12.131638918486905, 42.80817434377764] },
    { alpha3Code: "JAM", coords: [-77.32207028342654, 18.138327151737354] },
    { alpha3Code: "JOR", coords: [36.78299510018287, 31.254797127882966] },
    { alpha3Code: "JPN", coords: [138.05482210817596, 37.77140643900977] },
    { alpha3Code: "KAZ", coords: [67.2758776086993, 48.27760912569444] },
    { alpha3Code: "KEN", coords: [37.78737887987958, 0.5966342013009859] },
    { alpha3Code: "KGZ", coords: [74.62022047795132, 41.514418780985864] },
    { alpha3Code: "KHM", coords: [104.87552396911929, 12.687575429366092] },
    { alpha3Code: "KOR", coords: [127.82076808792867, 36.43615563968397] },
    { alpha3Code: "KWT", coords: [47.602650261235404, 29.305561942125717] },
    { alpha3Code: "LAO", coords: [103.73845213794279, 18.459937554632806] },
    { alpha3Code: "LBN", coords: [35.872727112268656, 33.91026067002216] },
    { alpha3Code: "LBR", coords: [-9.412876750333155, 6.434756628483775] },
    { alpha3Code: "LBY", coords: [17.965147820452653, 27.0451090518824] },
    { alpha3Code: "LKA", coords: [80.66438759955085, 7.703827774452265] },
    { alpha3Code: "LSO", coords: [28.171839880058027, -29.62607218141937] },
    { alpha3Code: "LTU", coords: [23.877424573218494, 55.28647096953985] },
    { alpha3Code: "LUX", coords: [5.96917307072639, 49.76478785105263] },
    { alpha3Code: "LVA", coords: [24.834637725819714, 56.80880736148726] },
    { alpha3Code: "MAR", coords: [-8.388783122715099, 29.962528523947693] },
    { alpha3Code: "MDA", coords: [28.40701241013225, 47.20973667896971] },
    { alpha3Code: "MDG", coords: [46.67955968112222, -19.393222448447364] },
    { alpha3Code: "MEX", coords: [-102.55796683683863, 24.029493715960694] },
    { alpha3Code: "MKD", coords: [21.698812192946363, 41.60760880443979] },
    { alpha3Code: "MLI", coords: [-3.534245323089657, 17.305730493527374] },
    { alpha3Code: "MMR", coords: [96.48038841193805, 21.06663024061322] },
    { alpha3Code: "MNE", coords: [19.285780176779106, 42.79147121180769] },
    { alpha3Code: "MNG", coords: [102.93082533305127, 46.8668036844191] },
    { alpha3Code: "MOZ", coords: [35.4560939823193, -17.28629970493624] },
    { alpha3Code: "MRT", coords: [-10.320105874095965, 20.243413160276923] },
    { alpha3Code: "MWI", coords: [34.19167308175869, -13.182176113326662] },
    { alpha3Code: "MYS", coords: [109.6921160074707, 3.727380009003647] },
    { alpha3Code: "NAM", coords: [17.154015520727235, -22.142229960019407] },
    { alpha3Code: "NCL", coords: [165.534166270717, -21.26391318342559] },
    { alpha3Code: "NER", coords: [9.327711509783324, 17.37034300437444] },
    { alpha3Code: "NGA", coords: [7.995094820341236, 9.557741276450189] },
    { alpha3Code: "NIC", coords: [-85.01850207972852, 12.850449624725576] },
    { alpha3Code: "NLD", coords: [5.517522977754095, 52.303485941416874] },
    { alpha3Code: "NOR", coords: [15.538253078896421, 69.9327482605375] },
    { alpha3Code: "NPL", coords: [84.00824299024492, 28.244285885990564] },
    { alpha3Code: "NZL", coords: [172.6486792096679, -41.73745790782924] },
    { alpha3Code: "OMN", coords: [56.09592111618093, 20.627072783071156] },
    { alpha3Code: "PAK", coords: [69.41250895589074, 30.034881908558766] },
    { alpha3Code: "PAN", coords: [-80.10702655764433, 8.528999578495542] },
    { alpha3Code: "PER", coords: [-74.35115466337041, -9.222273278436594] },
    { alpha3Code: "PHL", coords: [122.86372988900393, 11.797996125020374] },
    { alpha3Code: "PNG", coords: [145.30751651805144, -6.455836518105749] },
    { alpha3Code: "POL", coords: [19.30971561857255, 52.16739550850507] },
    { alpha3Code: "PRI", coords: [-66.48233966312368, 18.2361701227371] },
    { alpha3Code: "PRK", coords: [127.16297979171166, 40.154614709189225] },
    { alpha3Code: "PRT", coords: [-8.053669141495778, 39.64911878803597] },
    { alpha3Code: "PRY", coords: [-58.37763165588769, -23.26534710522382] },
    { alpha3Code: "PSE", coords: [35.27188590896611, 31.939847848382648] },
    { alpha3Code: "QAT", coords: [51.185199050836914, 25.320304656869787] },
    { alpha3Code: "ROU", coords: [24.94230822218841, 45.867743707432616] },
    { alpha3Code: "RUS", coords: [99.75378706539892, 62.56393049001938] },
    { alpha3Code: "RWA", coords: [29.920329605199914, -2.0119693824561193] },
    { alpha3Code: "ESH", coords: [-12.1272339109027, 24.31173641768118] },
    { alpha3Code: "SAU", coords: [44.49247454648626, 24.1857398175386] },
    { alpha3Code: "SDN", coords: [29.862494584297096, 16.027162844891183] },
    { alpha3Code: "SSD", coords: [30.197582990067154, 7.2982299410119] },
    { alpha3Code: "SEN", coords: [-14.508675901940832, 14.3571135465676] },
    { alpha3Code: "SLB", coords: [159.96753888964275, -8.857112560238624] },
    { alpha3Code: "SLE", coords: [-11.795087734544579, 8.532521352327471] },
    { alpha3Code: "SLV", coords: [-88.87348191888952, 13.725941933756465] },
    { alpha3Code: "SOM", coords: [45.71916357000065, 4.760504336102432] },
    { alpha3Code: "SRB", coords: [20.819301616077876, 44.240048082768595] },
    { alpha3Code: "SUR", coords: [-55.91329799955379, 4.121688778349168] },
    { alpha3Code: "SVK", coords: [19.50932541437121, 48.72822527259617] },
    { alpha3Code: "SVN", coords: [14.939214686322407, 46.12755269355106] },
    { alpha3Code: "SWE", coords: [16.612972202421297, 62.962277476269] },
    { alpha3Code: "SWZ", coords: [31.39646053006985, -26.489233617656325] },
    { alpha3Code: "SYR", coords: [38.546352933162105, 35.02293796421906] },
    { alpha3Code: "TCD", coords: [18.57959228005208, 15.372287127683876] },
    { alpha3Code: "TGO", coords: [0.9975387285924853, 8.441956207511062] },
    { alpha3Code: "THA", coords: [100.98287873585852, 15.046043583736646] },
    { alpha3Code: "TJK", coords: [71.03259094280178, 38.589242702772054] },
    { alpha3Code: "TKM", coords: [59.26575488810073, 39.10961031437566] },
    { alpha3Code: "TLS", coords: [125.96690107978135, -8.766555994231389] },
    { alpha3Code: "TTO", coords: [-61.32884763492872, 10.428967451687486] },
    { alpha3Code: "TUN", coords: [9.532066339605747, 34.191993887646454] },
    { alpha3Code: "TUR", coords: [35.1153508674448, 39.083019200900665] },
    { alpha3Code: "TWN", coords: [120.97275279066702, 23.74570855203611] },
    { alpha3Code: "TZA", coords: [34.749984310340565, -6.2666955176997465] },
    { alpha3Code: "UGA", coords: [32.35602915624533, 1.2947075333062275] },
    { alpha3Code: "UKR", coords: [31.225427588632318, 49.16920629806867] },
    { alpha3Code: "URY", coords: [-56.000555510202005, -32.78809050033177] },
    { alpha3Code: "USA", coords: [-112.47032461819829, 47.104129393155965] },
    { alpha3Code: "UZB", coords: [63.18568107398365, 41.775817686206565] },
    { alpha3Code: "VEN", coords: [-66.15340754144627, 7.171134481383215] },
    { alpha3Code: "VNM", coords: [106.23521930455047, 16.720764290115277] },
    { alpha3Code: "VUT", coords: [167.07378268814716, -15.548350455380797] },
    { alpha3Code: "YEM", coords: [47.535478236707426, 15.920565875631029] },
    { alpha3Code: "ZAF", coords: [25.035259956401475, -28.992358314534616] },
    { alpha3Code: "ZMB", coords: [27.720335253632022, -13.408470240040044] },
    { alpha3Code: "ZWE", coords: [29.78599128946607, -18.915874228986773] }
]

export default markers;
